const API_URL = process.env.REACT_APP_API_URL;

const LOGIN_HEADERS = (body) => {
    return {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            user: body.userInput,
            password: body.passInput
        })
    }
};

const TOKEN_VALIDATOR = (credentials) => {
    return {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: credentials.token,
            userId: credentials.id,
            rolId: credentials.rol,
            clientId: credentials.cliente
        })
    }
};

const headerPostStructure = (token) => {
    return {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: {}
    }
};

const headerPutStructure = (token) => {
    return {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: {}
    }
};

const headerDeleteStructure = (token) => {
    return {
        method: 'DELETE',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: {}
    }
};


const GET_HEADERS = (token) => {
    return {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': token
        },
    }
};

const DATA_HEADERS = (credentials, body) => {
    var headers = headerPostStructure(credentials.token);
    headers.body = JSON.stringify({
        fechaIn: body.startDate,
        fechaFin: body.endDate,
        tipoBusqueda: body.tipoBusqueda,
        branchID: body.branchID,
        customerID: credentials.cliente,
        rol: credentials.rol,
      });

    return headers;
};

const GET_BRANCHES_HEADERS = (credentials) => {
    var headers = headerPostStructure(credentials.token);
    headers.body = JSON.stringify({
        customerID: credentials.cliente,
      });

    return headers;
}

const GET_HEATMAP_RESUME_HEADERS = (credentials, body) => {
    var headers = headerPostStructure(credentials.token);
    headers.body = JSON.stringify({
        fechaIn: body.startDate,
        fechaFin: body.endDate,
        tipoBusqueda: body.tipoBusqueda,
        branchID: body.branchID,
        customerID: credentials.cliente,
        rol: credentials.rol,
      });
    return headers;
}

const ADD_USER_HEADERS = (credentials, body) => {
    var headers = headerPostStructure(credentials.token);
    headers.body = JSON.stringify({
        user: body.user,
        password: body.password,
        customerID: body.customerID,
        rolID: body.rolID
    });

    return headers;
};


const ADD_CLIENT_HEADERS = (credentials, body) => {
    var headers = headerPostStructure(credentials.token);
    headers.body = JSON.stringify({
        name: body.cli_nombre,
        rfc: body.cli_rfc,
        color: body.cli_PrimaryColor,
        pathLogo: body.cli_PathLogo
    });

    return headers;
};

const CHANGE_PASSWORD = (credentials, body) => {
    var headers = headerPostStructure(credentials.token);
    headers.body = JSON.stringify({
        password: body.password,
    });

    return headers;
};

const EDIT_HALL_NAME_HEADERS = (credentials, body) => {
    var headers = headerPutStructure(credentials.token);
    headers.body = JSON.stringify({
        locationID: body.locationID,
        renameTo: body.renameTo,
        customerID: credentials.cliente,
    });

    return headers;
};

const EDIT_USERS_HEADERS = (credentials, body) => {
    var headers = headerPutStructure(credentials.token);
    headers.body = JSON.stringify({
        user: body.user,
        customerID: body.customerID,
        rolID: body.rolID
    });

    return headers;
};

const EDIT_CLIENTS_HEADERS = (credentials, body) => {
    var headers = headerPutStructure(credentials.token);
    headers.body = JSON.stringify({
        name: body.cli_nombre,
        rfc: body.cli_rfc,
        pathLogo: body.cli_PathLogo,
		color: body.cli_PrimaryColor
    });

    return headers;
};

const MYPROFILE = (token) => {
    return {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': token
        },
    }
};

const MYCLIENT = (token) => {
    return {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': token
        },
    }
};

const DELETE_CLIENT = (credentials, body) => {
    var headers = headerDeleteStructure(credentials.token);
    headers.body = JSON.stringify({
        name: body.cli_nombre,
        rfc: body.cli_rfc,
        pathLogo: body.cli_PathLogo,
		color: body.cli_PrimaryColor
    });

    return headers;
};

const DELETE_USER = (credentials, body) => {
    var headers = headerDeleteStructure(credentials.token);
    headers.body = JSON.stringify({
        user: body.user,
        customerID: body.customerID,
        rolID: body.rolID
    });

    return headers;
};

const UPLOAD_IMAGE = (credentials, image) => {
    const formData  = new FormData();
    formData.append('image', image);
    var headers = headerPostStructure(credentials.token);
    headers.body = formData;

    delete headers.headers['Content-Type'];

    return headers;
};



export {
    API_URL,
    DATA_HEADERS,
    GET_BRANCHES_HEADERS,
    TOKEN_VALIDATOR,
    LOGIN_HEADERS,
    GET_HEADERS,
	DELETE_CLIENT,
    ADD_USER_HEADERS, 
	ADD_CLIENT_HEADERS,
	MYPROFILE,
	DELETE_USER,
	EDIT_USERS_HEADERS,
	EDIT_CLIENTS_HEADERS,
	CHANGE_PASSWORD,
	MYCLIENT,
    UPLOAD_IMAGE,
    GET_HEATMAP_RESUME_HEADERS,
    EDIT_HALL_NAME_HEADERS
};