/*!

=========================================================
* Now UI Dashboard React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {React, useState, useEffect} from "react";

import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Modal, 
  CardTitle,
  Button,
  Table,
  Row,
} from "reactstrap";
// core components

import PanelHeader from "components/PanelHeader/PanelHeader.js";

import {
  API_URL,
  GET_HEADERS,
  DELETE_USER,
  MYPROFILE
} from "variables/connection.js";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { 
  faArrowRight,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

library.add(faArrowRight);
library.add(faArrowLeft);

const Usuarios = () => {
	const credentials = JSON.parse(localStorage.getItem('credentials'));
	const [data , setData] = useState([]);
	const [pageSize, setPageSize] = useState(5);
    const [pageIndex, setPageIndex] = useState (0);
	const [openModal, setOpenModal] = useState(false);
	const [user, setUser] = useState('');
	const [password, setPassword] = useState ('');
	const [customerID, setClient] = useState();
	const [rolID, setRol] = useState();
	const [id , setId] = useState ('');

	useEffect (() => {
    fetch( API_URL + '/user', GET_HEADERS(credentials.token))
	    .then((res) => res.json())
		  .then (res => {
			  let array = [];
			  if(credentials.rol == 2){
			  for (var i = 0; i < res[0].length ; i++){
				  if((res[0][i].fk_cli)==(credentials.cliente)){
					  array.push (res[0][i]);
				  }
			  }
				setData(array);
			  }
			  else{
				setData(res [0]);  
			  }
		  });

	}, []);
	
	const toggle = (id) => {
		setOpenModal(!openModal);
		setId (id);
		fetch(API_URL + '/user/' + id, MYPROFILE(credentials.token))
	    .then((res) => res.json())
		.then (res => {
			setUser(res[0][0].usr_logon);
			setPassword(res[0][0].usr_pwd);
			setClient(res[0][0].fk_cli);
			setRol(res[0][0].fk_tus);
		});
	}
	
	const handleNextPageClick = (event) => {
		setPageIndex ( 
			pageIndex < Math.floor(data.length / pageSize)
			? pageIndex + 1
			: pageIndex
		);
	}
	
	const handlePrevPageClick = (event) => {
		setPageIndex ( 
			pageIndex > 0 ? pageIndex - 1: 0
		);
	}
	
	const borrar = () => {
		const body = {
			user: user,
			customerID: customerID,
			rolID:rolID,
		};
		fetch( API_URL + '/user/' + id, DELETE_USER(credentials, body))
			.then(response => {
				if(response.status === 200) window.location.href = (credentials.rol == 1 ? '/admin' : '/client') + '/users';
			})
			.catch(error => console.log("Error"));
	}

	const handlePageSize= (event) => {
		setPageSize(event.target.value);
		setPageIndex(0);
	}

  return (
    <>
      <PanelHeader size="sm" />
      {/* Page content */}
      <div className="content">
		<Modal
          isOpen={openModal}
          toggle={toggle}
        >
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">
              Eliminar usuario
            </h3>
          </div>
          <div className="modal-body">¿Estas seguro que deseas eliminar este usuario?</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggle (0)}
            >
              Cerrar
            </Button>
            <Button color="primary" type="button" onClick={borrar}>
              Confirmar
            </Button>
          </div>
        </Modal>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="card" color="primary" outline>
              <CardHeader>
				<Row>
					<div className="col-10">
						<CardTitle className="heading-small text-muted mb-4" tag="h4">
							Listado de usuarios
						</CardTitle>
					</div>
					<div className="col">
						<Link to={(credentials.rol == 1 ? '/admin' : '/client') + '/addUser'}>
							<Button color="primary">
							Agregar usuario
							</Button>
						</Link>
					</div>
				</Row>
              </CardHeader>
			  <CardBody>
              <Table responsive hover className="table-flush">
                <thead className="thead-light">
                  <tr>
					<th scope="col">ID</th>
                    <th scope="col">Nombre de usuario</th>
					<th scope="col">Estatus</th>
					<th scope="col">Cliente</th>
					<th scope="col">Fecha de alta</th>
					<th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
				{data
				.slice(
                pageIndex * pageSize,
                pageIndex * pageSize + pageSize
				)
				.map (item => {
					return(
                  <tr key={item.pk_usr}>
					<td>{item.pk_usr}</td>
                    <td>{item.usr_logon}</td>
					<td>{item.usr_activo}</td>
					<td>{item.fk_cli}</td>
					<td>{(item.usr_fechaAltaReal).substring(0,10)}</td>
                    <td>
						<Link to={(credentials.rol == 1 ? '/admin' : '/client') + '/editUser?id=' + item.pk_usr}>
							<Button 
								className="btn btn-fab btn-icon btn-round"
								style={{ backgroundColor:"#242C77"}} 
							>
								<i className="now-ui-icons arrows-1_refresh-69"></i>
							</Button>
						</Link>
						<Button 
						onClick={() => toggle (item.pk_usr)}
						className="btn btn-fab btn-icon btn-round" 
						style={{ backgroundColor:"#373d3f"}}>
							<i className="now-ui-icons ui-1_simple-remove"></i>
						</Button>
                    </td>
                  </tr>
				  );
				})}
                </tbody>
              </Table>
			   </CardBody>
              <CardFooter className="py-4">
                <CardFooter className="py-4">
				<div className="col" style={{
					marginRight: 0,
					marginLeft: 'auto',
					width: '250px'
				}}>
					<select className="form-control" onChange={handlePageSize} style={{
						display: 'inline',
						width: '75px',
						marginRight: '5px',
						padding: '10px'
					}}>
                        <option value={5}>5</option>
						<option value={10}>10</option>
						<option value={20}>20</option>
						<option value={50}>50</option>
                    </select>
					<Button color="primary" onClick={event => handlePrevPageClick(event)}>
						<FontAwesomeIcon icon="arrow-left" style={{color: "#FFF"}}/>
					</Button>
					<Button className="btn btn-info btn-fab" onClick={event => handleNextPageClick(event)}>
						<FontAwesomeIcon icon="arrow-right" style={{color: "#FFF"}}/>
					</Button>

				</div>
              </CardFooter>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
};

export default Usuarios;