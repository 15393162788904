// reactstrap components
import { useRef, useState } from "react";

import {
  Alert,
  Button,
  Card,
  CardBody,
  Row,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";

import {
  API_URL,
  LOGIN_HEADERS
} from "variables/connection.js";

import logoWebCore from "assets/img/logoWebCore-bk.svg";
import { longArrowLeft } from "fontawesome";

const Login = () => {
  const [userInput, setUserInput] = useState('');
  const [passInput, setPassInput] = useState('');
  const [openWarning, setOpenWarning] = useState(false);
  const handleUserChange = (event) => {
    setUserInput(event.target.value);
  };
  const handlePassChange = (event) => {
    setPassInput(event.target.value);
  };
  const verifyCredentials = (event) =>{
    setOpenWarning(false);
    const body = {
      userInput: userInput,
      passInput: passInput
    };
    fetch( API_URL + '/login', LOGIN_HEADERS(body))
        .then(response => {
          if(response.status !== 200) throw new Error(response.status);
            return response.json()
        })
        .then(data => {
          localStorage.setItem('credentials', JSON.stringify(data));
          window.location.href = (data.rol == 1 ? '/admin' : '/client') + '/dashboard';
        })
        .catch(error => setOpenWarning(true));
  };
  const getOldToken = () => {
    var isOldToken = false;
    let urlString = location.href;
    let paramString = urlString.split('?')[1];
    let queryString = new URLSearchParams(paramString);
    for(let pair of queryString.entries()) {
      if (pair[0].includes('isOldToken')){
        isOldToken = true;
      }
    }
    return isOldToken;
  };
  const [isOldToken, setIsOldToken] = useState(getOldToken);
  return (
    <>
      <Col className="order-xl-1" xl="5" 
        style={{
          margin: 'auto',
          // backgroundColor:"#D6D6D6"
        }}>
          <div className="logo-img m-5 text-center">
            <img src={logoWebCore} width="200" alt="SmartMetrics - WebCore" />
          </div>
        <Card >
            <div className="text-muted text-center" >
              
              <Alert color="danger" isOpen={openWarning} toggle={() => {setOpenWarning(false)}}>
                <span>
                  <b>Revisa tu usuario y/o contraseña</b>
                </span>
              </Alert> 
              <Alert color="warning" isOpen={isOldToken} toggle={() => {setIsOldToken(false)}}>
                <span>
                  La sesión ha expirado, vuelve a iniciar sesión
                </span>
              </Alert> 
            </div>
          <CardBody className="px-lg-5 py-lg-0">
          
            <Form className="mt-5">
              <FormGroup>
              <label>Usuario</label>
                  <Input
                    className="form-control"
                    onChange={handleUserChange}
                    type="text"
                  />
              </FormGroup>
              
              <FormGroup>
                
              <label>Contraseña</label>    
                  <Input
                    onChange={handlePassChange}
                    type="password"
                  />
              </FormGroup>
              <div className="d-none custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                  checked
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Recuérdame</span>
                </label>
              </div>
              <div className="text-center mt-4 mb-5">
                <Button className="btn btn-primary btn-lg btn-block" color="primary" type="button" onClick={verifyCredentials}>
                  Iniciar sesión
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;