/*!

=========================================================
* Now UI Dashboard React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Auth from "./Auth";

import routes from "routes.js";

var ps;

function validateSession(){
  var isRolCorrect = false
  if(localStorage.getItem('credentials')){
    var credential = JSON.parse(localStorage.getItem('credentials'))
    isRolCorrect = credential.rol && credential.rol == 1
  }
  
  return isRolCorrect;
}

function Admin(props) {
  if(validateSession()){
    const location = useLocation();
    const backgroundColor = "blue";
    const mainPanel = React.useRef();
    React.useEffect(() => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(mainPanel.current);
        document.body.classList.toggle("perfect-scrollbar-on");
      }
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
          document.body.classList.toggle("perfect-scrollbar-on");
        }
      };
    });
    React.useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainPanel.current.scrollTop = 0;
    }, [location]);
    return (
      <div className="wrapper">
        <Sidebar {...props} routes={routes} rolName={'admin'} backgroundColor={backgroundColor} />
        <div className="main-panel" ref={mainPanel}>
          <DemoNavbar {...props} rolName={'admin'} />
          <Switch>
            {routes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
  else{
    return(
      <Redirect to="/auth/login" />
    );
  }
}

export default Admin;
