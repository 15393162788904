/*!

=========================================================
* Now UI Dashboard React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import ClientesList from "views/Client/ClientesList.js";
import UsersList from "views/User/UsersList.js";
import AddClient from "views/Client/AddClient.js";
import EditClient from "views/Client/EditClient.js";
import AddUser from "views/User/AddUser.js";
import EditUser from "views/User/EditUser.js";
import Perfil from "views/User/Perfil.js";
import Password from "views/User/NewPassword.js";
import Login from "views/Login/Login.js";

var dashRoutes = [
  // Admin Routes
  {
    path: "/dashboard",
    name: "WebCore",
    icon: "design_app",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: "Clientes",
    icon: "business_briefcase-24",
    component: ClientesList,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: "users_single-02",
    component: UsersList,
    layout: "/admin",
  },
  {
    path: "/addClient",
    component: AddClient,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/editClient",
    component: EditClient,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/addUser",
    component: AddUser,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/editUser",
    component: EditUser,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/myprofile",
    component: Perfil,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/newpassword",
    component: Password,
    layout: "/admin",
    redirect: true,
  },

  // Client Routes
  {
    path: "/dashboard",
    name: "Vista de sucursal",
    icon: "shopping_shop",
    component: Dashboard,
    layout: "/client",
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: "users_single-02",
    component: UsersList,
    layout: "/client",
  },
  {
    path: "/addUser",
    component: AddUser,
    layout: "/client",
    redirect: true,
  },
  {
    path: "/editUser",
    component: EditUser,
    layout: "/client",
    redirect: true,
  },
  {
    path: "/myprofile",
    component: Perfil,
    layout: "/client",
    redirect: true,
  },
  {
    path: "/newpassword",
    component: Password,
    layout: "/client",
    redirect: true,
  },

  // shared
  {
    path: "/login",
    component: Login,
    layout: "/auth",
    redirect: true,
  },
];
export default dashRoutes;

