import { MatrizTable } from "../MatrizTable/MatrizTable";
import h337 from "heatmap.js";
import "./HeatMap.css";
import { useEffect, useState } from "react";

var heatmapInstance;
const getPosition = (id) => {
  var posicion = { x: 0, y: 0 };
  posicion.x = document.getElementById(id).offsetLeft;
  posicion.y = document.getElementById(id).offsetTop;

  return posicion;
};

export function obtenerHeatMapInstance(heatMapChartCellSelected, hallsLimits) {
  var element = document.querySelector(".heatmap");
  var radio = element.clientWidth / 4;
  element.innerHTML = "";
  heatmapInstance = h337.create({
    container: element,
    radius: radio,
    maxOpacity: 0.5,
  });
  const { x, y } = getPosition(
    `${heatMapChartCellSelected.yPosition}${heatMapChartCellSelected.xPosition}`
  );
  heatmapInstance.setData({
    max: hallsLimits[heatMapChartCellSelected.deviceId].max,
    data: [
      {
        x,
        y,
        value: heatMapChartCellSelected.y,
      },
    ],
  });
}

export function renderizarHeatmap(hallsTotals) {
  var element = document.querySelector(".heatmap");
  if (typeof element != "undefined" && element != null) {
    var radio = element.clientWidth / 4;
    element.innerHTML = "";
    heatmapInstance = h337.create({
      container: element,
      radius: radio,
      maxOpacity: 0.5,
    });
    var points = [];
    var max = 0;
    var val;

    if (hallsTotals != null || hallsTotals != undefined) {
      for (var hall of hallsTotals) {
        var posicion = getPosition(`${hall.yPosition}${hall.xPosition}`);

        var x = posicion.x;
        var y = posicion.y;

        val = hall.y;
        max = Math.max(max, val);
        var point = {
          x: x,
          y: y,
          value: val,
        };
        points.push(point);
      }
    }

    var data = {
      max: max,
      data: points,
    };

    if (data.max === 0) {
      data.max = 100;
    }

    heatmapInstance = heatmapInstance.repaint();
    heatmapInstance.setData(data);
  }
}

export const HeatMap = ({ layoutUrl }) => {
  const [margen,setMargen]=useState(0);
  var tooltip = document.querySelector(".tool");
  var tooldato = document.querySelector(".dato");

  function updateTooltip(x, y, value) {
    var transl = "translate(" + x + "px, " + y + "px)";
    tooltip.style.webkitTransform = transl;
    tooldato.innerHTML = value;
  }

  const handleMouseMove = (ev) => {

    // var xdat=ev.nativeEvent.layerX + margen + 30;
    // var ydat=ev.nativeEvent.layerY;
    
    var xdat=ev.pageX+15;
    var ydat=ev.pageY+15;

    var x = ev.nativeEvent.layerX;
    var y = ev.nativeEvent.layerY;

    var value = 0;
    if (typeof heatmapInstance != "undefined" && heatmapInstance != null) {
      value = heatmapInstance.getValueAt({
        x: x,
        y: y,
      });
    }
    var sin_datos = document.getElementById("error-datos");
    if (typeof sin_datos == "undefined" || sin_datos == null) {
    tooltip.style.display = "block";
    updateTooltip(xdat, ydat, value);
    }
  };


  // hide tooltip on mouseout
  const onmouseout = () => {
    tooltip.style.display = "none";
  };


  useEffect(()=>{
    var margin =document.querySelector(".heatmap");
    const estilo=window.getComputedStyle(margin);
   
    setMargen(parseInt(estilo.marginLeft));
  },[])
  return (
    <>
      <div className="mapa"
            
      >
        <MatrizTable number={50}></MatrizTable>

        <div
          className="heatmap"
          onMouseMove={handleMouseMove}
          onMouseLeave={onmouseout}
          style={{
            backgroundImage: `url(${layoutUrl})`,
          }}
        ></div>
        <div
          className="tool"
          style={{
            display: "none",
            transform: `translate(20px, 20px)`,
          }}
        >
          <div className="info">
            <div className="item">
              <div className="cuadrado" id="valor"></div>
              <div className="dato">10</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
