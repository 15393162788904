import { React, useState, useEffect } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faFilter } from "@fortawesome/free-solid-svg-icons";

function HeatMapResumeChart({ data, setHeatMapChartCellSelected }) {
  const [formatoTermometro] = useState(">-,.2d");
  const [datosHoraSemanal, setDatosHoraSemanal] = useState([]);
  const [cell, setCell] = useState(null);

  useEffect(() => {
    const info = [...data]
    setDatosHoraSemanal(info);
  }, [data]);

  const CustomCellsLayer = () => {
    const { x, y, height, width } = cell;
    return (
      <g
        onClick={() => {
          setCell(null);
          setHeatMapChartCellSelected(null);
        }}
        style={{ cursor: "pointer" }}
      >
        <g transform={`translate(${x - width / 2}, ${y - height / 2})`}>
          <rect
            width={width}
            height={height}
            fill={"rgba(19, 92, 247, .1)"}
            stroke="#135cf7"
            strokeWidth={1}
            style={{
              filter: "drop-shadow(0px 0px 2px #135cf7)",
            }}
          />
        </g>
      </g>
    );
  };

  const ClearCellSelectedContainer = (
    <Card className="shadow-none m-0 p-0 mt-4">
      <CardBody className="p-0">
        {cell && (
          <div className="card col-12 rounded-2 shadow-sm border border-2">
            <div className="row d-flex align-items-center p-2 px-3 justify-content-center">
              <div className="col-lg-10 col-sm-12 p-0">
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <FontAwesomeIcon icon={faFilter} style={{ color: "#000" }} />
                  <span className=" font-weight-bold">
                    Mostrando información del pasillo {cell?.data.x} a las{" "}
                    {cell?.serieId} hrs.
                  </span>
                </div>
              </div>
              <div className="col-lg-2 col-sm-12 d-flex justify-content-end p-0">
                <Button
                  color="danger"
                  style={{
                    gap: "10px",
                  }}
                  className="d-flex align-items-center"
                  onClick={() => {
                    setCell(null);
                    setHeatMapChartCellSelected(null);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ color: "#fff" }}
                  />
                  <span>Limpiar</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );

  return (
    <>
      <Card className="shadow-none m-0 p-0">
        <CardBody className="p-0">
          <div className="chart-area">
            <ResponsiveHeatMap
              onClick={(cell) => {

                const { data } = cell;

                setCell(cell);
                setHeatMapChartCellSelected(data);
              }}
              data={datosHoraSemanal}
              margin={{ top: 150, right: 20, left: 70, bottom: 0 }}
              valueFormat={formatoTermometro}
              axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legendPosition: "middle",
                legendOffset: -35,
                truncateTickAt: 5,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: -55,
              }}
              colors={{
                type: "diverging",
                scheme: "reds",
                divergeAt: 0.9,
              }}
              labelTextColor="#000"
              theme={{
                labels: {
                  text: {
                    color: "#007acd",
                    textShadow: "0 0 5px #fff, 0 0 6px #fff, 0 0 7px #fff",
                    fontWeight: "bold",
                  },
                },
              }}
              layers={
                cell
                  ? [
                    "grid",
                    "axes",
                    "cells",
                    CustomCellsLayer,
                    "legends",
                    "annotations",
                  ]
                  : ["grid", "axes", "cells", "legends", "annotations"]
              }
            />
          </div>
          {ClearCellSelectedContainer}
        </CardBody>
      </Card>
    </>
  );
}

export default HeatMapResumeChart;
