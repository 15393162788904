import { faPen, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { EDIT_HALL_NAME_HEADERS } from "variables/connection";
import { API_URL } from "variables/connection";

export default function UpdateHeatMapChartModal({ halls, setRefresh }) {
  const credentials = JSON.parse(localStorage.getItem("credentials"));
  const [toggle, setToogle] = useState(false);
  const [selectedHall, setSelectedHall] = useState(null);
  const [newHallName, setNewHallName] = useState(null);
  const [showError, setError] = useState(false);
  const [showSucces, setSucces] = useState(false);

  useEffect(() => {
    setSelectedHall(halls[0]?.locationID);
  }, [halls]);

  const handleSelectChange = (e) => {
    setSelectedHall(e.target.value);
  };

  const handleNameChange = (e) => {
    setError(false);
    setNewHallName(e.target.value);
  };

  const handleUpdate = () => {
    if (!newHallName || !newHallName.length) {
      setError(true);
      return;
    }

    fetch(
      API_URL + "/data/puertas/name",
      EDIT_HALL_NAME_HEADERS(credentials, {
        locationID: selectedHall,
        renameTo: newHallName,
      })
    )
      .then(() => {
        setToogle(false);
        setSucces(true);
        setRefresh(old => !old)
      })
      .finally(() => {
        setSelectedHall(halls[0]?.locationID);
        setNewHallName(null);
        setToogle(false);
        setInterval(() => {                                                        
          setSucces(false)                                                                 
          setError(false);
        }, 3000);
      });
  };

  return (
    <div className="col-12 d-flex justify-content-end shadow-none m-0 p-0">
      {showSucces ? (
        <Alert
          className="d-flex align-items-center col-12"
          style={{ gap: "5px", background: '#5cb85c' }}
          disabled
        >
          <FontAwesomeIcon icon={faCheck} style={{ color: "#fff" }} />
          <span>Nombre actualizado</span>
        </Alert>
      ) : (
        <Button
          onClick={() => setToogle(true)}
          className="d-flex align-items-center"
          style={{ gap: "5px" }}
        >
          <FontAwesomeIcon icon={faPen} style={{ color: "#fff" }} />
          <span>Editar pasillos</span>
        </Button>
      )}

      <Modal isOpen={toggle} toggle={() => setToogle(false)}>
        <ModalHeader toggle={() => setToogle(false)}>
          Editar nombre de los pasillos
        </ModalHeader>
        <ModalBody>
          {showError && (
            <Alert color="danger">Todos los campos son requeridos</Alert>
          )}
          <FormGroup>
            <Label for="select-hall">Nombre actual</Label>
            <Input
              type="select"
              name="select"
              id="select-hall"
              value={selectedHall}
              onChange={handleSelectChange}
            >
              {halls?.map((hall) => (
                <option key={hall.locationID} value={hall.locationID}>
                  {hall?.deviceName}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="renamed-hall">Nombre nuevo</Label>
            <Input
              type="text"
              name="renamed-hall"
              id="renamed-hall"
              value={newHallName}
              onChange={handleNameChange}
              placeholder="Ingrese el nuevo nombre"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setToogle(false)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleUpdate}>
            Actualizar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
