/*!

=========================================================
* Now UI Dashboard React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {React, useState, useEffect} from "react";

// reactstrap components
import {
  Card,
  Button,
  CardHeader,
  CardFooter,
  CardTitle,
  CardBody,
  Modal, 
  Table,
  Row,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import {
  API_URL,
  GET_HEADERS,
  DELETE_CLIENT,
  MYCLIENT,
} from "variables/connection.js";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { 
  faArrowRight,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

library.add(faArrowRight);
library.add(faArrowLeft);



function ClientList() {
	const credentials = JSON.parse(localStorage.getItem('credentials'));
	const [data, setData] = useState([]);
	const [dataCliente, setDataCliente] = useState([]);
	const [pageSize, setPageSize] = useState(5);
    const [pageIndex, setPageIndex] = useState(0);
	const [id , setId] = useState ('');
	const [openModal, setOpenModal] = useState(false);
	const [cli_nombre, setCli_nombre] = useState('');
	const [cli_PrimaryColor, setCli_PrimaryColor] = useState('');
	const [cli_PathLogo, setCli_PathLogo] = useState('');
	const [cli_rfc, setCli_rfc] = useState('');
	
	const toggle = (id) => {
		setOpenModal(!openModal);
		setId (id);
		fetch(API_URL + '/client/' + id, MYCLIENT(credentials.token))
	    .then((res) => res.json())
		.then (res => {
			setCli_nombre(res[0][0].cli_nombre);
			setCli_PrimaryColor(res[0][0].cli_PrimaryColor);
			setCli_PathLogo(res[0][0].cli_PathLogo);
			setCli_rfc(res[0][0].cli_rfc);
		});
	}

	useEffect (() => {
    fetch( API_URL + '/client', GET_HEADERS(credentials.token))
	    .then((res) => res.json())
		.then (res => {
			setData(res [0]);
		});

	}, []);
	
	const handleNextPageClick = (event) => {
		setPageIndex ( 
			pageIndex < Math.floor(data.length / pageSize)
			? pageIndex + 1
			: pageIndex
		);
	}
	
	const handlePrevPageClick = (event) => {
		setPageIndex ( 
			pageIndex > 0 ? pageIndex - 1: 0
		);
	}

	const handlePageSize= (event) => {
		setPageSize(event.target.value);
		setPageIndex(0);
	}

	
	const borrar = () => {
		const body = {
			cli_nombre: cli_nombre,
			cli_rfc: cli_rfc,
			cli_PrimaryColor: cli_PrimaryColor,
			cli_PathLogo: cli_PathLogo,
		};
		fetch( API_URL + '/client/' + id, DELETE_CLIENT(credentials, body))
			.then(response => {
				if(response.status === 200) {
					setId('');
					window.location.href = '/admin/clients';
				}
			})
			.catch(error => console.log("Error"));
	}
	
  return (
    <>
      <PanelHeader size="sm" />
	  
      <div className="content">
		<Modal
          isOpen={openModal}
          toggle={toggle}
        >
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">
              Desactivar cliente
            </h3>
          </div>
          <div className="modal-body">¿Estas seguro que deseas desactivar este cliente?</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggle (0)}
            >
              Cerrar
            </Button>
            <Button color="primary" type="button" onClick={borrar}>
              Confirmar
            </Button>
          </div>
        </Modal>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="card" color="primary" outline>
              <CardHeader> 
				<Row>
					<div className="col-10">
						<CardTitle className="heading-small text-muted mb-4" tag="h4">
							Listado de clientes
						</CardTitle>
					</div>
					<div className="col">
					<Button color="primary" type="button" href="/admin/addClient">
					  Agregar cliente
					</Button>
					</div>
				</Row>
              </CardHeader>
			  <CardBody>
              <Table responsive hover className="table-flush">
                <thead className="thead-light">
                  <tr>
					<th>ID</th>
					<th>Nombre</th>
					<th>Rfc</th>
					<th>Color</th>
					<th>Fecha de alta</th>
					<th>Estatus</th>
					<th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
				{data
				.slice(
                pageIndex * pageSize,
                pageIndex * pageSize + pageSize
				)
				.map (item => {
					return(
				<tr key={item.pk_cli}>
					<td>{item.pk_cli}</td> 
                    <td>{item.cli_nombre}</td> 
                    <td>{item.cli_rfc}</td>
					<td>{item.cli_PrimaryColor}</td>
					<td>{(item.cli_fechaalta).substring(0,10)}</td>
					<td>{item.cli_estatus}</td>
                    <td>
						<Button  
							className="btn btn-fab btn-icon btn-round" 
							style={{ backgroundColor:"#242C77"}} 
							href={"/admin/editClient?id=" + item.pk_cli}>
							<i className="now-ui-icons arrows-1_refresh-69"></i>
						</Button>
						<Button 
							onClick={() => toggle (item.pk_cli)}
							//onClick={() => {obtenerID(item.pk_cli); }}
							className="btn btn-info btn-fab btn-icon btn-round">
							<i className="now-ui-icons ui-1_simple-remove"></i>
						</Button>
                    </td>
                  </tr>
				  );
				})}
                </tbody>
              </Table>
			  </CardBody>
              <CardFooter className="py-4">
				<div className="col" style={{
					marginRight: 0,
					marginLeft: 'auto',
					width: '250px'
				}}>
					<select className="form-control" onChange={handlePageSize} style={{
						display: 'inline',
						width: '75px',
						marginRight: '5px',
						padding: '10px'
					}}>
                        <option value={5}>5</option>
						<option value={10}>10</option>
						<option value={20}>20</option>
						<option value={50}>50</option>
                    </select>
					<Button color="primary" onClick={event => handlePrevPageClick(event)}>
						<FontAwesomeIcon icon="arrow-left" style={{color: "#FFF"}}/>
					</Button>
					<Button className="btn btn-info btn-fab" onClick={event => handleNextPageClick(event)}>
						<FontAwesomeIcon icon="arrow-right" style={{color: "#FFF"}}/>
					</Button>

				</div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
}

export default ClientList;
