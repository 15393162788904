import {React, useState} from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  InputGroup,
  FormFeedback,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";


import {
	API_URL,
	ADD_CLIENT_HEADERS,
	UPLOAD_IMAGE
} from "variables/connection.js";

const AddClient = () => {
	const credentials = JSON.parse(localStorage.getItem('credentials'));
	const [cli_nombre, setName] = useState('')
	const [cli_rfc, setRfc] = useState('')
	const [cli_PrimaryColor, setColor] = useState('')
	const [cli_PathLogo, setPathLogo] = useState('')
	const [cliImage, setCliImage] = useState({});
	const [invalidNombre, setInvalidN] = useState(false);
	const [invalidRfc, setInvalidRFC] = useState(false);
	const [invalidLogo, setInvalidLogo] = useState(true);
	const [invalidColor, setInvalidColor] = useState(true);
	const enviarDatos = (event) => {
		event.preventDefault();
		setInvalidN(false);
		setInvalidRFC(false);
		if( cli_nombre != '' && cli_rfc == '') setInvalidRFC(true);
		else if (cli_nombre == '' && cli_rfc != '') setInvalidN(true);
		else if (cli_nombre == '' && cli_rfc == '') {
			setInvalidRFC(true);
			setInvalidN(true);
		}
		const body = {
			cli_nombre: cli_nombre,
			cli_rfc: cli_rfc,
			cli_PrimaryColor: cli_PrimaryColor,
			cli_PathLogo: cli_PathLogo,
		};
		fetch( API_URL + '/client', ADD_CLIENT_HEADERS(credentials, body))
			.then(response => {
				fetch(API_URL + '/client/uploadFile', UPLOAD_IMAGE(credentials, cliImage))
					.then(res => {
						if(response.status === 200) window.location.href = '/admin/clients';
					});
			})
			.catch(error => console.log("Error"));
	}
	
  return (
    <>
      <PanelHeader size="sm" />
      {/* Page content */}
      <div className="content">
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
				<CardHeader> 
				<Row>
					<div className="col-10">
						<CardTitle className="heading-small text-muted ml-3" tag="h3">
							Agregar cliente
						</CardTitle>
					</div>
				</Row>
				</CardHeader>
				<CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label className="form-control-label" htmlFor="input-username">
                            Nombre
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons users_single-02" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input 
							invalid = {invalidNombre}
							className="form-control-alternative" 
							onChange = {event => setName (event.target.value)} 
							placeholder="Nombre del cliente" 
							type="text"/>
						  <FormFeedback invalid={invalidNombre.toString()}>
							  El nombre del cliente es obligatorio.
						  </FormFeedback>
						  </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label
                            className="form-control-label"
                            htmlFor="input-email"
                        >
                            Rfc
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons business_badge" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input
							invalid = {invalidRfc}
                            className="form-control-alternative"
                            onChange = {event => setRfc (event.target.value)}
                            placeholder="Rfc"
							style={{ textTransform:"uppercase"}} 
                            type="text"
                          />
						  <FormFeedback invalid={invalidRfc.toString()}>
							  El RFC es obligatorio
						  </FormFeedback>
						  </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
					  <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label
                            className="form-control-label"
                            htmlFor=""
                        >
                            Color de fondo
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons design_palette" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input
							invalid={invalidColor}
                            className="form-control-label"
                            onChange = {event => { setColor (event.target.value); event.target.value != '' ? setInvalidColor(false) : setInvalidColor(true)}}
                            type="color"
                          />
						  <FormFeedback invalid={invalidColor.toString()}>
							  El color es obligatorio.
						  </FormFeedback>
						  </InputGroup>
                        </FormGroup>
                      </Col>
					   <Col lg="6">
						<FormGroup className={"no-border"}>	
							<label
                            className="form-control-label"
                            htmlFor=""
							>
                            Seleccionar logo
							</label>
							<Input
								invalid={invalidLogo}
								accept= "image/*"
								className="form-control-label"
								onChange = {event => {
									setPathLogo(API_URL + "/" + event.target.files[0].name); 
									event.target.value != '' ? setInvalidLogo(false) : setInvalidLogo(true);
									setCliImage(event.target.files[0]);
								}}
								type="file"
							/>
							<FormFeedback invalid={invalidLogo.toString()}>
							  Por favor, selecciona un archivo.
						    </FormFeedback>
						</FormGroup>
					  </Col>
                    </Row>
					<div className="text-center">
						<Button className="my-4" color="dark" type="button" href="/admin/clients">
						  Salir
						</Button>
						<Button className="my-4" onClick={enviarDatos} color="primary" type="button" href="/admin/clients">
						  Guardar
						</Button>
					</div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddClient;