import React, {useState, useEffect} from "react";

import {
  Button,
  Card,
  Alert,
  CardBody,
  CardHeader,
  FormFeedback,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import icons from "variables/icons";

import {
  API_URL,
  CHANGE_PASSWORD
} from "variables/connection.js";
import { Link } from "react-router-dom";


const Password = () => {
	const credentials = JSON.parse(localStorage.getItem('credentials'));
	const [password, setNewPass] = useState();
	const [confirmPass, setConfirmPass] = useState();
	const [openWarning, setOpenWarning] = useState(false);
	
	const enviarDatos = (event) => {
		event.preventDefault();
		setOpenWarning(false);
		if (password == confirmPass){
			const body = {
			password: password,
		};
		fetch( API_URL + '/user/changePassword/' + credentials.id, CHANGE_PASSWORD(credentials, body))
			.then(response => {
				if(response.status === 200) {
					console.log("Cambio correcto");
					window.location.href = (credentials.rol == 1 ? '/admin' : '/client') + '/dashboard';
				}
			})
			.catch(error => console.log("Error"));
		}
		else {
			setOpenWarning(true)
		}
			
	}
	return (
    <>
      <PanelHeader size="sm" />
      {/* Page content */}
      <div className="content">
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
				<CardHeader> 
				<Row>
					<div className="col-10">
						<CardTitle className="heading-small text-muted ml-3" tag="h3">
							Cambiar contraseña
						</CardTitle>
					</div>
				</Row>
				</CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
					  <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label className="form-control-label" htmlFor="input-username">
                            Contraseña
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons ui-1_lock-circle-open" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input className="form-control-alternative" id="input-username"  onChange = {event => setNewPass (event.target.value)} placeholder="Nueva contraseña" type="password"/>
						  </InputGroup>
                        </FormGroup>
                      </Col>
					  <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label className="form-control-label">
                            Confirmar Contraseña
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons ui-1_lock-circle-open" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input className="form-control-alternative" onChange = {event => setConfirmPass (event.target.value)} placeholder="Confirmar nueva contraseña" type="password"/>
						</InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
					<Alert color="danger" isOpen={openWarning} toggle={() => {setOpenWarning(false)}}>
						<span>
						  <b> Alerta - </b> Las contraseñas no coinciden
						</span>
					</Alert> 
					<div className="text-center">
						<Link to={(credentials.rol == 1 ? '/admin' : '/client') + '/dashboard'}>
							<Button color="dark">
								Salir
							</Button>
						</Link>
						<Button color="primary" onClick={enviarDatos}>
						  Actualizar
						</Button>
					</div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Password;