import { useState, useRef } from "react";
import "./tabla.css";
export const MatrizTable = ({ number }) => {
  //apartado para buscar la coordenada
  const coordenada = useRef(null);
  const [coordenadaSeleccionada, setcoordenadaSeleccionada] = useState(0);
  //genera la matriz
  const abecedario = " ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  var arreglo = Array(number).fill(1);
  var index = -1;
  var indexb = 0;
  var indexc = 0;

  return (
    <>
      <div className="matriz">
        <table className="coordenadas">
          {arreglo.map(() => (
            <tr
              id={
                indexb < 26
                  ? `${abecedario[indexc] == " " ? "" : abecedario[indexc]}${
                      abecedario[++indexb]
                    }`
                  : `${abecedario[++indexc]}${abecedario[(indexb = 1)]}`
              }
            >
              {arreglo.map(() => (
                <td
                  id={`${abecedario[indexc] == " " ? "" : abecedario[indexc]}${
                    abecedario[indexb]
                  }${index < number - 1 ? ++index : (index = 0)}`}
                ></td>
              ))}
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};
