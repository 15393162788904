import {React, useState, useEffect} from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import {
  API_URL,
  MYPROFILE,   
  EDIT_USERS_HEADERS,
  GET_HEADERS,
} from "variables/connection.js";
import { Link } from "react-router-dom";

const EditUser = () => {
	const credentials = JSON.parse(localStorage.getItem('credentials'));
	const id = window.location.search.substring(4);
	const idP = parseInt (id,10);
	const [user, setUser] = useState('');
	const [password, setPassword] = useState ('');
	const [customerID, setClient] = useState();
	const [rolID, setRol] = useState();
	const [data , setData] = useState([]);
	const [invalidUser, setInvalidU] = useState(false);
	const [invalidCustomer, setInvalidC] = useState(false);
	const [invalidRol, setInvalidR] = useState(false);
	
	useEffect (() => {
    fetch(API_URL + '/user/' + idP, MYPROFILE(credentials.token))
	    .then((res) => res.json())
		.then (res => {
			setUser(res[0][0].usr_logon);
			setPassword(res[0][0].usr_pwd);
			setClient(res[0][0].fk_cli);
			setRol(res[0][0].fk_tus);
		});

	}, []);
	
	useEffect (() => {
	if (credentials.rol == 1){
    fetch( API_URL + '/client', GET_HEADERS(credentials.token))
	    .then((res) => res.json())
		.then (res => {
			setData(res [0]);
		});

	}
	else{
	setRol(credentials.rol);
	setClient(credentials.cliente);
	}
	}, []);
	
	const enviarDatos = (event) => {
		event.preventDefault();
		setInvalidU(false);
		if( user == '') setInvalidU(true);
		const body = {
			user: user,
			customerID: customerID,
			rolID:rolID,
		};
		fetch( API_URL + '/user/' + idP, EDIT_USERS_HEADERS(credentials, body))
			.then(response => {
				if(response.status === 200) window.location.href = (credentials.rol == 1 ? '/admin' : '/client') + '/users';
			})
			.catch(error => console.log("Error"));
	}
	
	return (
    <>
      <PanelHeader size="sm" />
      {/* Page content */}
      <div className="content">
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
				<CardHeader> 
				<Row>
					<div className="col-10">
						<CardTitle className="heading-small text-muted ml-3" tag="h3">
							Editar usuario
						</CardTitle>
					</div>
				</Row>
				</CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label className="form-control-label" htmlFor="input-username">
                            Nombre del usuario
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons users_single-02" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input 
							invalid = {invalidUser}
							className="form-control-alternative"  
							value={user} 
							onChange = {event => setUser(event.target.value)} 
							type="text"/>
						  <FormFeedback invalid={invalidUser.toString()}>
							  El nombre de usuario es obligatorio.
						  </FormFeedback>
						  </InputGroup>
                        </FormGroup>
                      </Col>
					  <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label className="form-control-label" htmlFor="input-username">
                            Cliente
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons business_briefcase-24" />
							</InputGroupText>
						  </InputGroupAddon>
						  {credentials.rol == 1 && 
							<Input 
								invalid={invalidCustomer} 
								type="select" 
								value={customerID} 
								className="form-control" 
								onChange = {event => {setClient(event.target.value); event.target.value != 0 ? setInvalidC(false) : setInvalidC(true)}}>
								{data.map (item => {
									return(
									<option key={item.pk_cli} value={item.pk_cli}>{item.cli_nombre}</option>
									);
								})}
							</Input>
						  }
						  {credentials.rol != 1 && 
							<Input 
								invalid={false} 
								type="select"
								className="form-control" 
								disabled
								value={credentials.cliente} 
							>
								<option value={credentials.cliente}>{credentials.cli_nombre}</option> 
							</Input>
						  }
							<FormFeedback invalid={invalidCustomer.toString()}>
							  Por favor, selecciona una opción.
							</FormFeedback>
						  </InputGroup>
                        </FormGroup>
                      </Col>
					  
                    </Row>
					<Row>
					  <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label className="form-control-label" htmlFor="input-username">
                            Rol de usuario
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons users_circle-08" />
							</InputGroupText>
						  </InputGroupAddon>
						  {credentials.rol == 1 && 
							<Input 
								invalid={invalidRol} 
								type="select" 
								value={rolID} 
								className="form-control" 
								onChange = {event => {setRol(event.target.value); event.target.value != 0 ? setInvalidR(false) : setInvalidR(true)}}>
									<option value={1}>Administrador</option>
									<option value={2}>Cliente</option>
							</Input>
						  }
						  {credentials.rol != 1 && 
							<Input 
								invalid={false} 
								type="select" 
								disabled
								className="form-control" 
							>
								<option value={credentials.rol}>Cliente</option>
							</Input>
						  }
							<FormFeedback invalid={invalidRol.toString()}>
							  Por favor, selecciona una opción.
							</FormFeedback>
						  </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
					<div className="text-center">
						<Link to={(credentials.rol == 1 ? '/admin' : '/client') + '/users'}>
							<Button color="dark">
								Salir
							</Button>
						</Link>
						<Button color="primary" onClick={enviarDatos}>
						  Guardar
						</Button>
					</div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditUser;