import moment from "moment";
import { useEffect, useState } from "react";
import "./style.css";

export const CurrentHourCard = () => {
  const [data, setDate] = useState(new Date());

  useEffect(() => {
    const updateHour = () => {
      setDate(new Date());
    };
    const intervalId = setInterval(updateHour, 6000);
    updateHour();
    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (date) => {
    return moment(date).format("hh:mm A");
  };

  return (
    <div className="col-xl-4 col-lg-4 rounded-2">
      <div className="card card-stats py-1 shadow-sm border border-2">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col color-tag">
              <h6 className="text-muted">Hora actual</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <span className="h2 mb-0 font-weight-bold">
                {formatTime(data)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentHourCard;
