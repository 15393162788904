import React, {useState, useEffect} from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle, CardHeader,
  InputGroup,
  InputGroupAddon,
  FormFeedback,
  InputGroupText,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import icons from "variables/icons";
import {
  API_URL,
  MYCLIENT,
  EDIT_CLIENTS_HEADERS,
  UPLOAD_IMAGE
} from "variables/connection.js";

const EditClient = () => {
	const credentials = JSON.parse(localStorage.getItem('credentials'));
	const id = window.location.search.substring(4);
	const idP = parseInt (id,10);
	const [cli_nombre, setCli_nombre] = useState('');
	const [cli_PrimaryColor, setCli_PrimaryColor] = useState('#000000');
	const [cli_PathLogo, setCli_PathLogo] = useState('');
	const [cliImage, setCliImage] = useState(null);
	const [cli_rfc, setCli_rfc] = useState('');
	const [invalidNombre, setInvalidN] = useState(false);
	const [invalidRfc, setInvalidRFC] = useState(false);
	const [invalidLogo, setInvalidLogo] = useState(true);
	
	useEffect (() => {
    fetch(API_URL + '/client/' + idP, MYCLIENT(credentials.token))
	    .then((res) => res.json())
		.then (res => {
			setCli_nombre(res[0][0].cli_nombre);
			setCli_PrimaryColor(res[0][0].cli_PrimaryColor);
			setCli_PathLogo(res[0][0].cli_PathLogo);
			setCli_rfc(res[0][0].cli_rfc);
		});

	}, []);
	
	const enviarDatos = (event) => {
		event.preventDefault();
		setInvalidN(false);
		setInvalidRFC(false);
		if( cli_nombre != '' && cli_rfc == '') setInvalidRFC(true);
		else if (cli_nombre == '' && cli_rfc != '') setInvalidN(true);
		else if (cli_nombre == '' && cli_rfc == '') {
			setInvalidRFC(true);
			setInvalidN(true);
		}
		const body = {
			cli_nombre: cli_nombre,
			cli_rfc: cli_rfc,
			cli_PrimaryColor: cli_PrimaryColor,
			cli_PathLogo: cli_PathLogo,
		};
		fetch( API_URL + '/client/' + idP, EDIT_CLIENTS_HEADERS(credentials, body))
			.then(response => {
				if(cliImage){
					fetch(API_URL + '/client/uploadFile', UPLOAD_IMAGE(credentials, cliImage))
						.then(res => {
							if(response.status === 200) window.location.href = '/admin/clients';
						});
				}
				else {
					if(response.status === 200) window.location.href = '/admin/clients';
				}
			})
			.catch(error => console.log("Error"));
	}
	
	return (
    <>
      <PanelHeader size="sm" />
      {/* Page content */}
      <div className="content">
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
				<CardHeader> 
					<Row>
						<div className="col-10">
							<CardTitle className="heading-small text-muted ml-3" tag="h3">
								Editar cliente
							</CardTitle>
						</div>
					</Row>
				</CardHeader>
				<CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label className="form-control-label" htmlFor="input-username">
                            Nombre
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons users_single-02" />
							</InputGroupText>
						  </InputGroupAddon>
							<Input 
							  invalid = {invalidNombre}
							  className="form-control-alternative" 
							  value={cli_nombre} 
							  onChange = {event => setCli_nombre (event.target.value)} 
							  type="text"
							/>
							<FormFeedback invalid={invalidNombre.toString()}>
							  El nombre del cliente es obligatorio.
						    </FormFeedback>
						  </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label
                            className="form-control-label"
                        >
                            Rfc
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons business_badge" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input
							invalid = {invalidRfc}
							style={{ textTransform:"uppercase"}} 
                            className="form-control-alternative"
                            value={cli_rfc}
							onChange = {event => setCli_rfc (event.target.value)} 
                            type="text"
                          />
						  <FormFeedback invalid={invalidRfc.toString()}>
							  El RFC es obligatorio
						  </FormFeedback>
						  </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
					  <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label
                            className="form-control-label"
                        >
                            Color de fondo
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons design_palette" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input
                            className="form-control-label"
                            type="color"
							value={cli_PrimaryColor}
							onChange = {event => setCli_PrimaryColor (event.target.value)}
                          />
						  </InputGroup>
                        </FormGroup>
                      </Col>
					  <Col lg="6">
						<FormGroup className={"no-border"}>	
							<label
                            className="form-control-label"
                            htmlFor=""
							>
                            Seleccionar logo
							</label>
							<Input
								accept= "image/*"
								className="form-control-label"
								type="file"
								onChange = {
									event => {
										setCli_PathLogo(API_URL + "/" + event.target.files[0].name); 
										setCliImage(event.target.files[0]);
									}
								} 
							/>
							<label
                            className="form-control-label"
							>
							</label>
						</FormGroup>
					  </Col>
                    </Row>
					<div className="text-center">
						<Button className="my-4" color="dark" type="button" href="/admin/clients">
						  Salir
						</Button>
						<Button className="my-4" color="primary" type="button" onClick={enviarDatos}>
						  Guardar
						</Button>
					</div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditClient;