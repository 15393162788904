import { React, useState, useEffect, useRef } from "react";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import { Chart } from "chart.js";

// reactstrap components
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledDropdown,
  Button,
} from "reactstrap";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { graficaPersonas, graficaPuertas } from "variables/charts.js";

import {
  API_URL,
  DATA_HEADERS,
  GET_BRANCHES_HEADERS,
  GET_HEATMAP_RESUME_HEADERS,
} from "variables/connection.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCloudArrowDown,
  faSearchLocation,
  faHistory,
  faCalendarAlt,
  faRocket,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx/xlsx.mjs";
import * as fs from "fs";
XLSX.set_fs(fs);
import { Readable } from "stream";
XLSX.stream.set_readable(Readable);
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
XLSX.set_cptable(cpexcel);

import moment from "moment";
import conteoHoraSemanalInicial from "../variables/horas_por_dia.json";
import TermometroSemanal from "./Graficas/TermometroSemanal";

import CurrentHourCard from "./Dashbord/components/CurrentHourCard";

import {
  HeatMap,
  renderizarHeatmap,
  obtenerHeatMapInstance,
} from "./Dashbord/components/HeatMap/HeatMap";
import ResumeInfoCard from "./Dashbord/components/ResumeInfoCard";
import HeatMapResumeChart from "./Dashbord/components/HeatMapResumeChart";

import UpdateHeatMapChartModal from "./Dashbord/components/UpdateHeatMapChartModal";

library.add(faCloudArrowDown);
library.add(faSearchLocation);
library.add(faHistory);
library.add(faCalendarAlt);
library.add(faRocket);
library.add(faArrowUp);
library.add(faArrowDown);

function Dashboard() {
  const credentials = JSON.parse(localStorage.getItem("credentials"));
  const rol = credentials.rol;
  const [periodo, setPeriodo] = useState({
    startDate: formatDateToday().replaceAll("-", ""),
    endDate: formatDateToday().replaceAll("-", ""),
  });
  const [periodoDos, setPeriodoDos] = useState({
    startDate: formatDateToday().replaceAll("-", ""),
    endDate: formatDateToday().replaceAll("-", ""),
  });

  const [tipoBusqueda, setTipoBusqueda] = useState("Horas");
  const [sucursales, setSucursales] = useState([
    { pk_suc: 1, suc_desc: "Sin sucursales" },
  ]);
  const [sucursalID, setSucursalID] = useState(1);
  const [totalPersonas, setTotalPersonas] = useState(0);
  const [conteoPersonas, setConteoPersonas] = useState({});
  const [openWarning, setOpenWarning] = useState(false);
  const [diferenciaPeriodos, setDiferenciaPeriodos] = useState(0);
  const [conteoHoraSemanal, setConteoHoraSemanal] = useState(
    conteoHoraSemanalInicial
  );
  const [porcentajeHoraSemanal, setPorcentajeHoraSemanal] = useState(
    conteoHoraSemanalInicial
  );
  const [colorPorcentaje, setColorPorcentaje] = useState(
    "text-warning font-weight-bold"
  );
  const [iconTendency, setIconTendency] = useState([]);

  const [inputDate, setInputDate] = useState("");
  const [inputDateTwo, setInputDateTwo] = useState("");
  const [comparativePeriod, setComparativePeriod] = useState("");
  const [lastCounter, setLastCounter] = useState("");

  const [minVisits, setMinVisits] = useState(null);
  const [maxVisits, setMaxVisits] = useState(null);
  const [hallsLimits, setHallsLimits] = useState(null);
  const [halls, setHalls] = useState(null);
  const [heatMapData, setHeatMapData] = useState([]);
  const [hallsTotals, setHallsTotals] = useState([]);
  const [heatMapChartCellSelected, setHeatMapChartCellSelected] =
    useState(null);
  const [refresh, setRefresh] = useState(false);
  const [layoutUrl, setLayout] = useState("");
  const [heatmapError, setHeatMapError] = useState(null);

  const [dynamicMessage, setDynamicMessage] = useState();
  const linePersonas = useRef();
  const barPuertas = useRef();

  function formatDateToday() {
    let d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleCallback = (start, end, label) => {
    setPeriodo((anterior) => ({
      startDate: start.format("YYYYMMDD"),
      endDate: end.format("YYYYMMDD"),
    }));
  };
  const handleCallbackDos = (start, end, label) => {
    setPeriodoDos((anterior) => ({
      startDate: start.format("YYYYMMDD"),
      endDate: end.format("YYYYMMDD"),
    }));
  };

  const handleChangeTipoBusqueda = (event) =>
    setTipoBusqueda(event.target.value);

  const handleSucursalChange = (event) =>
    setSucursalID(parseInt(event.target.value));

  const sendData = () => {
    const body = {
      startDate: periodo.startDate,
      endDate: periodo.endDate,
      tipoBusqueda: tipoBusqueda,
      branchID: sucursalID,
    };

   
    fetch(API_URL + "/data", DATA_HEADERS(credentials, body))
      .then((response) => response.json())
      .then((data) => {
        var labels = new Array();
        var dataValue = new Array();
        var chartData = {
          dataPersonas: {},
          dataPuertas: {},
          puertas: {},
          startDate: periodo.startDate,
          endDate: periodo.endDate,
          tipoBusqueda: tipoBusqueda,
        };

        let personasCount = 0;
        for (var propiedad of data.personas) {
          labels.push(propiedad.x);
          dataValue.push(propiedad.y);
          personasCount += parseInt(propiedad.y);
        }
        setTotalPersonas(personasCount.toLocaleString("en-US"));
        chartData.dataPersonas = changeDataPersonas(labels, dataValue);
        labels = new Array();
        dataValue = new Array();

        for (var propiedad of data.puertas) {
          labels.push(propiedad.addDevice);
          dataValue.push(propiedad.y);
        }
        chartData.dataPuertas = changeDataPuertas(labels, dataValue);
        chartData.puertas = data.puertas;

        if (rol !== 4) {
          let diferenciaPeriodoVar = 0;

          if (data.diferencia[0].mDiffPercent == null) {
            diferenciaPeriodoVar = 0;
          } else {
            diferenciaPeriodoVar = Math.round(
              parseFloat(data.diferencia[0].mDiffPercent)
            );
          }

          if (diferenciaPeriodoVar == 0) {
            setColorPorcentaje("text-warning font-weight-bold");
          } else if (diferenciaPeriodoVar > 0) {
            setColorPorcentaje("text-success font-weight-bold");
            setIconTendency("fa-arrow-up");
          } else {
            setColorPorcentaje("text-danger font-weight-bold");
            setIconTendency("fa-arrow-down");
          }
          setDiferenciaPeriodos(diferenciaPeriodoVar);
        }

        //Obtiene las fechas y las regresa en un arreglo
        const obtainDates = () => {
          const { StartDate, EndDate, mBef_DT1, mBef_DT2 } = data.diferencia[0];
          const periodDates = [StartDate, EndDate, mBef_DT1, mBef_DT2];
          return periodDates;
        };

        //Muestra el conteo del periodo pasado
        const lastPeriodCount = () => {
          const lastCount = data.diferencia[0].CounterOUTBef;
          let showCount = "Total de personas de este periodo " + lastCount;
          return showCount;
        };
        setLastCounter(lastPeriodCount());

        //Toma el arreglo de fechas y les da un formato deseado
        const datesFormat = obtainDates().map((date) => {
          let yearDate = date.substring(2, 4);
          let monthDate = date.substring(4, 6);
          let dayDate = date.substring(6);
          let dateCorrect = `${dayDate}/${monthDate}/${yearDate}`;
          return dateCorrect;
        });

        // Agrega la fecha/ fechas seleccionadas
        const showCountDateInitial = () => {
          const [dateI, dateIEnd, ,] = datesFormat;
          let initialsDates = ` ${dateI} al ${dateIEnd}`;
          return dateI == dateIEnd ? ` ${dateI}` : initialsDates;
        };
        setInputDate(showCountDateInitial());

        //Agrega las fechas que comparara con las fechas seleccionadas
        const showCountDateEnd = () => {
          const [, , dateEnd, dateEnd2] = datesFormat;
          let datesEnds = `que el periodo del ${dateEnd} al ${dateEnd2}`;
          return dateEnd == dateEnd2 ? `que el ${dateEnd}` : datesEnds;
        };
        setComparativePeriod(showCountDateEnd());

        setConteoHoraSemanal(data.conteo_por_hora_semanal);

        setPorcentajeHoraSemanal(data.porcentaje_por_hora_semanal);

        setConteoPersonas(chartData);
      })

      .catch((errorSendData) => {
        console.log(errorSendData);
      });
  };

  const getExcelPersonas = () => {
    const body = {
      startDate: periodo.startDate,
      endDate: periodo.endDate,
      tipoBusqueda: tipoBusqueda,
      branchID: sucursalID,
    };
    fetch(API_URL + "/data", DATA_HEADERS(credentials, body))
      .then((response) => response.json())
      .then((data) => {
        var filename = "graficaPersonas";
        let datos = [];
        if (data.puertas.length == 0 && data.personas.length == 0) {
          mensaje();
        } else {
          for (var dato of data.personas) {
            if (dato.x)
              dato.x = dato.x.charAt(0).toUpperCase() + dato.x.slice(1);
            if (dato.y) dato.y = parseInt(dato.y);
            let nuevoDato = (({ x, y }) => ({ x, y }))(dato);
            nuevoDato[tipoBusqueda] = nuevoDato["x"];
            delete nuevoDato["x"];
            nuevoDato["Cantidad"] = nuevoDato["y"];
            delete nuevoDato["y"];
            datos.push(nuevoDato);
          }
          var ws = XLSX.utils.json_to_sheet(datos);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, filename);
          XLSX.writeFile(wb, filename + ".xlsx");
        }
      });
  };

  const getExcelPuertas = () => {
    var dataPoints, filename, dataP;
    filename = "graficaDispositivos";
    if (barPuertas.current) {
      const personas = barPuertas.current;
      dataPoints = personas.data.datasets[0].data;
      dataP = personas.data.labels;
      if (dataPoints.length == 0 && dataP.length == 0) {
        mensaje();
      } else {
        var ws = XLSX.utils.aoa_to_sheet([dataP, dataPoints]);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, filename);
        XLSX.writeFile(wb, filename + ".xlsx");
      }
    } else {
      mensaje();
    }
  };

  const getExcelInformeGeneral = () => {
    const body = {
      startDate: periodo.startDate,
      endDate: periodo.endDate,
      tipoBusqueda: tipoBusqueda,
      branchID: sucursalID,
    };
    fetch(API_URL + "/data/rawDataClient", DATA_HEADERS(credentials, body))
      .then((response) => response.json())
      .then((data) => {
        var filename = "graficaInformeGeneral";

        if (data.length == 0) {
          mensaje();
        } else {
          for (var dato of data) {
            if (dato.cantidad) dato.cantidad = parseInt(dato.cantidad);
          }
          var ws = XLSX.utils.json_to_sheet(data);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, filename);
          XLSX.writeFile(wb, filename + ".xlsx");
        }
      });
  };

  const mensaje = () => {
    setOpenWarning(true);
  };

  function changeDataPersonas(labels, data) {
    if (labels.length == 1) {
      labels.unshift("");
      data.unshift(null);
      labels.push("");
      data.push(null);
    }
    return {
      dataValue: data,
      labels: labels,
    };
  }

  function changeDataPuertas(labels, data) {
    var percentages = new Array();
    var cont = 0,
      i;
    for (i = 0; i < labels.length; i++) {
      percentages.push({
        label: labels[i],
        data: data[i],
      });
      cont += parseInt(data[i]);
    }
    percentages.sort(function (a, b) {
      return b.data - a.data;
    });
    labels = new Array();
    data = new Array();
    for (var percentage of percentages) {
      labels.push(percentage.label);
      data.push((percentage.data * 100) / cont);
    }
    return {
      dataValue: data,
      labels: labels,
    };
  }

  useEffect(() => {
    fetch(API_URL + "/data/branches", GET_BRANCHES_HEADERS(credentials))
      .then((response) => response.json())
      .then((data) => {
        setSucursales(data);
        if (data.length > 0) {
          setSucursalID(data[0].pk_suc);
        }
      });
  }, []);

  useEffect(() => {
    sendData();
    Chart.register({
      id: "NoValue",
      afterDraw: function (chart) {
        if (chart.data.datasets[0]) {
          if (chart.data.datasets[0].data.length === 0) {
            // No data is present
            var ctx = chart.ctx;
            var width = chart.width;
            var height = chart.height;
            chart.clear();

            ctx.save();
            ctx.fillStyle = "#32325d";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.font = "16px normal 'Helvetica Nueue'";
            ctx.fillText("Sin métricas para graficar.", width / 2, height / 2);
            ctx.restore();
          }
        }
      },
    });
  }, [periodo, sucursalID, tipoBusqueda, periodoDos, setInputDate]);

  useEffect(() => {
    if (heatMapChartCellSelected)
      obtenerHeatMapInstance(heatMapChartCellSelected, hallsLimits);
    else renderizarHeatmap(hallsTotals);
  }, [hallsTotals, heatMapChartCellSelected]);



  useEffect(() => {


    if (conteoPersonas) {

      var chartData;
      if (linePersonas.current) {
        chartData = conteoPersonas.dataPersonas;
        const personas = linePersonas.current;
        personas.data.datasets[0].data = chartData.dataValue;
        personas.data.labels = chartData.labels;
        personas.data.datasets[0].label = inputDate;

        const laOtraFecha = periodoDos;
        const nuevaLinea = [];
        const { startDate, endDate } = laOtraFecha;
        const periodosDos = [startDate, endDate];

        const newFormat = periodosDos.map((date) => {
          let yearDate = date.substring(2, 4);
          let monthDate = date.substring(4, 6);
          let dayDate = date.substring(6);
          let dateCorrect = `${dayDate}/${monthDate}/${yearDate}`;
          return dateCorrect;
        });

        const showCountDate = () => {


          const [dateI, dateIEnd] = newFormat;
          let initialsDatesTwo = ` ${dateI} al ${dateIEnd}`;
          return dateI == dateIEnd ? ` ${dateI}` : initialsDatesTwo;
        };

        if (laOtraFecha) {


          const body = {
            startDate: periodoDos.startDate,
            endDate: periodoDos.endDate,
            tipoBusqueda: tipoBusqueda,
            branchID: sucursalID,
          };

          fetch(API_URL + "/data", DATA_HEADERS(credentials, body))
            .then((response) => response.json())
            .then((data) => {

              for (let propiedad of data.personas) {
                nuevaLinea.push(propiedad.y);
              }
              personas.data.datasets[1].data = nuevaLinea;
              personas.data.datasets[1].label = showCountDate();

              personas.update();
            });
        } else {

          personas.update();
        }
      }

      if (barPuertas.current) {

        chartData = conteoPersonas.dataPuertas;
        const puertas = barPuertas.current;
        puertas.data.datasets[0].data = chartData.dataValue;
        puertas.data.labels = chartData.labels;
        puertas.update();
      }
    }

  }, [conteoPersonas]);
  useEffect(() => {
    sendData();
  }, [heatMapChartCellSelected])
  useEffect(() => {


    const body = {
      startDate: periodo.startDate,
      endDate: periodo.endDate,
      tipoBusqueda: tipoBusqueda,
      branchID: sucursalID,
    };
    fetch(
      API_URL + "/data/heatmap",
      GET_HEATMAP_RESUME_HEADERS(credentials, body)
    )
      .then(async (response) => response.json())
      .then((data) => {


        if (data?.error) {
          setHeatMapError(data.error);
          return;
        }

        setHeatMapError(null);
        console.log(data.heatMapSchedule);
        setHeatMapData(data.heatMapSchedule);
        setMinVisits(data.minAndMaxVisitsByHall.min);
        setMaxVisits(data.minAndMaxVisitsByHall.max);
        setHallsLimits(data.minAndMaxByHall);
        setHalls(data.halls);
        setHallsTotals(data.totalForEachHall);
        setLayout(data.layoutUrl);
      });
  }, [refresh, periodo, sucursalID, tipoBusqueda, periodoDos, setInputDate]);


  return (
    <>
      <PanelHeader
        content={
          <div className="content">
            <div className="row pt-3 pt-md-5">
              {/* Caja info: conteo de personas */}
              <div className="col-xl-3 col-lg-6">
                <div className="card card-stats mb-4 mb-xl-0">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="text-muted">Conteo total de personas</h6>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                      <div className="col-8">
                        <span
                          className="h3 mb-0 font-weight-bold"
                          title={inputDate}
                        >
                          {totalPersonas}
                        </span>
                      </div>
                      <div className="d-flex col-2 p-15 justify-content-end">
                        <a
                          onClick={getExcelInformeGeneral}
                          className="btn-round btn-outline-default btn-icon"
                          title="Descargar información"
                          style={{
                            background: "#7EC544",
                            borderColor: "transparent",
                            fontSize: "0.8rem",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon="cloud-arrow-down"
                            style={{ color: "#FFF" }}
                          />
                        </a>
                      </div>
                    </div>
                    {rol !== 4 && (
                      <div className="row align-items-baseline">
                        <div className="col">
                          <span className={colorPorcentaje}>
                            &nbsp;
                            <FontAwesomeIcon
                              icon={iconTendency}
                              style={{ color: { colorPorcentaje } }}
                            />
                            &nbsp;
                            {diferenciaPeriodos}% <b></b>
                          </span>{" "}
                          <span title={lastCounter}>
                            {diferenciaPeriodos >= 0 ? "más" : "menos"}{" "}
                            {comparativePeriod}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Caja filtro de sucursal */}
              <div className="col-xl-3 col-lg-6">
                <div className="card card-stats mb-4 mb-xl-0">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="text-muted">
                          <FontAwesomeIcon
                            icon="search-location"
                            style={{ color: "#48AAAD" }}
                          />
                          &nbsp; Sucursal
                        </h6>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                      <div className="col">
                        <div className="input-group">
                          <select
                            id="sucursal"
                            className="form-control"
                            onChange={handleSucursalChange}
                            style={{
                              cursor: "pointer",
                              fontSize: "1rem",
                            }}
                          >
                            {sucursales.map((sucursal) => {
                              return (
                                <option
                                  key={sucursal.pk_suc}
                                  value={sucursal.pk_suc}
                                >
                                  {sucursal.suc_desc}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Caja filtro fechas */}
              <div className="col-xl-4 col-lg-6">
                <div className="card card-stats mb-4 mb-xl-0">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col align-items-cente">
                        <h6 className="text-muted">
                          <FontAwesomeIcon
                            icon="calendar-alt"
                            style={{ color: "#48AAAD" }}
                          />
                          &nbsp; Fecha
                        </h6>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                      <div className="col">
                        <div className="input-group align-items-center">
                          <div style={{ width: "65%" }}>
                            <DateRangePicker
                              initialSettings={{
                                startDate: moment().startOf("hour"),
                                endDate: moment()
                                  .startOf("hour")
                                  .add(1, "hour"),
                                locale: {
                                  format: "DD/MM", //M/DD hh:mm A
                                  applyLabel: "Aplicar",
                                  cancelLabel: "Cancelar",
                                  fromLabel: "Desde",
                                  toLabel: "Hasta",
                                  daysOfWeek: [
                                    "dom",
                                    "lun",
                                    "mar",
                                    "mié",
                                    "jue",
                                    "vie",
                                    "sab",
                                  ],
                                  weekLabel: "S",
                                  monthNames: [
                                    "enero",
                                    "febrero",
                                    "marzo",
                                    "abril",
                                    "mayo",
                                    "junio",
                                    "julio",
                                    "agosto",
                                    "septiembre",
                                    "octubre",
                                    "noviembre",
                                    "diciembre",
                                  ],
                                },
                                ranges: {
                                  Hoy: [moment(), moment()],
                                  Ayer: [
                                    moment().subtract(1, "days"),
                                    moment().subtract(1, "days"),
                                  ],
                                  "Hace 7 días": [
                                    moment().subtract(6, "days"),
                                    moment(),
                                  ],
                                  "Hace 30 días": [
                                    moment().subtract(29, "days"),
                                    moment(),
                                  ],
                                  "Este mes": [
                                    moment().startOf("month"),
                                    moment().endOf("month"),
                                  ],
                                  "Mes anterior": [
                                    moment()
                                      .subtract(1, "month")
                                      .startOf("month"),
                                    moment()
                                      .subtract(1, "month")
                                      .endOf("month"),
                                  ],
                                },
                                minYear: 2021,
                                maxYear: 2030,
                                showWeekNumbers: false,
                                linkedCalendars: true,
                                alwaysShowCalendars: true,
                                autoApply: true,
                                showDropdowns: true,
                                showCustomRangeLabel: false,
                                opens: "center",
                              }}
                              onCallback={handleCallback}
                            >
                              <input
                                type="button"
                                className="form-control"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1rem",
                                }}
                              />
                            </DateRangePicker>
                          </div>
                          &nbsp;
                          <DateRangePicker
                            initialSettings={{
                              startDate: moment().startOf("hour"),
                              endDate: moment().startOf("hour").add(1, "hour"),
                              locale: {
                                //M/DD hh:mm A
                                applyLabel: "Aplicar",
                                cancelLabel: "Cancelar",
                                fromLabel: "Desde",
                                toLabel: "Hasta",
                                daysOfWeek: [
                                  "dom",
                                  "lun",
                                  "mar",
                                  "mié",
                                  "jue",
                                  "vie",
                                  "sab",
                                ],
                                weekLabel: "S",
                                monthNames: [
                                  "enero",
                                  "febrero",
                                  "marzo",
                                  "abril",
                                  "mayo",
                                  "junio",
                                  "julio",
                                  "agosto",
                                  "septiembre",
                                  "octubre",
                                  "noviembre",
                                  "diciembre",
                                ],
                              },

                              minYear: 2021,
                              maxYear: 2030,
                              showWeekNumbers: false,
                              linkedCalendars: true,
                              alwaysShowCalendars: true,
                              autoApply: true,
                              showDropdowns: true,
                              showCustomRangeLabel: false,
                              opens: "center",
                            }}
                            onCallback={handleCallbackDos}
                          >
                            <button
                              className="btn btn-info"
                              style={{ fontSize: "0.7rem", width: "30%" }}
                            >
                              Comparar
                            </button>
                          </DateRangePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Caja filtro */}
              <div className="col-xl-2 col-lg-6">
                <div className="card card-stats mb-4 mb-xl-0">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="text-muted">
                          <FontAwesomeIcon
                            icon="history"
                            style={{ color: "#48AAAD" }}
                          />
                          &nbsp; Filtros
                        </h6>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                      <div className="col">
                        <div className="input-group">
                          <select
                            className="form-control"
                            onChange={handleChangeTipoBusqueda}
                            style={{
                              cursor: "pointer",
                              fontSize: "1rem",
                            }}
                          >
                            <option value="Horas">Horas</option>
                            <option value="Dias">Días</option>
                            <option value="Meses">Meses</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <div className="content">
        <Alert
          color="warning"
          isOpen={openWarning}
          toggle={() => {
            setOpenWarning(false);
          }}
        >
          <span>
            <b> Alerta - </b> Sin datos crudos para exportar
          </span>
        </Alert>
        <Row>
          {/* Grafica de personas */}
          <Col xs={12} md={6}>
            <Card className="card-chart" style={{ backgroundColor: "#FFFFFF" }}>
              <CardHeader style={{ backgroundColor: "#FFFFFF" }}>
                <Row>
                  <Col xs={9} md={9}>
      
                    <CardTitle tag="h4">Personas</CardTitle>
                  </Col>
                  <Col xs={3} md={3}>
                    <UncontrolledDropdown>
                      <a
                        onClick={getExcelPersonas}
                        className="btn-round btn-outline-default btn-icon"
                        title="Descargar información"
                        style={{
                          background: "#7EC544",
                          borderColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          icon="cloud-arrow-down"
                          style={{ color: "#FFF" }}
                        />
                      </a>
                    </UncontrolledDropdown>
                  </Col>
                </Row>
                <hr />
              </CardHeader>
              <CardBody>
                <div className="chart-area">

                  <Line
                    ref={linePersonas}
                    data={graficaPersonas.data}
                    options={graficaPersonas.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* Grafica de pasillos */}
          <Col xs={12} md={6}>
            <Card className="card-chart" style={{ backgroundColor: "#FFFFFF" }}>
              <CardHeader style={{ backgroundColor: "#FFFFFF" }}>
                <CardTitle tag="h4">Pasillos</CardTitle>
                <UncontrolledDropdown>
                  <a
                    className="btn-round btn-outline-default btn-icon"
                    onClick={getExcelPuertas}
                    title="Descargar información"
                    style={{
                      background: "#7EC544",
                      borderColor: "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon="cloud-arrow-down"
                      style={{ color: "#FFF" }}
                    />
                  </a>
                </UncontrolledDropdown>
                <hr />
              </CardHeader>
              <CardBody>
                <div className="chart-area">

                  <Bar
                    ref={barPuertas}
                    data={graficaPuertas.data}
                    options={graficaPuertas.options}
                  />

                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          {/* Termometro */}
          <Col xs={12} md={12}>
            <TermometroSemanal
              conteoHoraSemanal={conteoHoraSemanal}
              porcentajeHoraSemanal={porcentajeHoraSemanal}
            ></TermometroSemanal>
          </Col>
        </Row>
        <Row>
          {/* Mapa de calor */}
          <Col xs={12} md={12}>
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">Mapa de calor</CardTitle>
                <hr />
              </CardHeader>
              <Row className="d-flex flex-row px-2">
                {/* <Col className="col-xl-7 col-lg-7"> */}
                <Col className={heatmapError ? "col-xl-12 col-lg-12" : "col-xl-7 col-lg-7"}>
                  <Row className="d-flex flex-column px-3">
                    <Col className="col-md-12 col-lg-12">
                      <Row>
                        <CurrentHourCard />
                        <ResumeInfoCard
                          title="Pasillo con más visitas"
                          value={heatmapError ? null : maxVisits}
                          color="success"
                        />
                        <ResumeInfoCard
                          title="Pasillo con menos visitas"
                          value={heatmapError ? null : minVisits}
                          color="danger"
                        />
                      </Row>
                    </Col>
                   
                    {heatmapError ? (
                      <>
                        <Col className="col-md-12 col-lg-12 mb-4 mt-1">
                          <span className="font-weight-bold" id="error-datos">
                            Sin metricas para graficar
                          </span>
                        </Col>

                      </>
                    ) : (
                      <>
                        <Col className="col-md-12 col-lg-12">
                          <UpdateHeatMapChartModal
                            halls={halls ?? []}
                            setRefresh={setRefresh}
                          />
                        </Col>

                      
                        <Col className="col-md-12 col-lg-12">
                          <HeatMapResumeChart
                            data={heatMapData}
                            setHeatMapChartCellSelected={
                              setHeatMapChartCellSelected
                            }
                          />
                        </Col>

                      </>
                    )}
                  </Row>
                </Col>
                {heatmapError ? (
                  <><Col className="col-xl-1 col-lg-1">
                  </Col>
                  </>
                ) : (
                  <>
                    <Col className="col-xl-5 col-lg-5">

                      <HeatMap
                        className="mapa"
                        layoutUrl={layoutUrl}
                      ></HeatMap>
                    </Col>
                  </>
                )}
              </Row>
            </Card>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default Dashboard;
