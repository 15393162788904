
// ##############################
// Function that converts a hex color number to a RGB color number
// #############################
function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

// ##############################
// general variables for charts
// #############################


let gradientChartOptionsConfiguration = {
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: true,
    },
  }
  ,
  responsive: true,
  scales: {
    y: {
      ticks: {
        maxTicksLimit: 7,
        padding: 5,
      },
    },
    y1: {
      display: false,
      grid: {
        drawOnChartArea: false,
      },
    },

  },
  layout: {
    padding: { left: 10, right: 10, top: 0, bottom: 10 },
  },
};


let graficaPuertasOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {

          return context.dataset.data[context.dataIndex].toFixed(2) + '%';
        }
      },
      bodySpacing: 4,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
  },
  responsive: 1,
  scales: {
    y: {
      grid: {
        drawTicks: false,
        drawBorder: false,
        borderDash: [2, 2]
      },
      ticks: {
        maxTicksLimit: 7,
        padding: 20,
      },
    },
    x: {
      grid: {
        drawTicks: false,
        display: false,
        drawBorder: false,
      },
      ticks: {
        padding: 20,
      },
    },
  },
  layout: {
    padding: { left: 10, right: 20, top: 20, bottom: 20 },
  },
};


export const graficaPersonas = {
  data: (canvas) => {
    
    return {
      labels: [''],
      datasets: [
        {
          label: '',
          borderColor: "#ab022f",
          backgroundColor: "#cd0c36",
          pointBorderColor: "#cd0c36",
          pointBackgroundColor: "#cd0c36",
          tension: 0.4,
          data: [],
          yAxisID: 'y',
        },
        {
          label: '',
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgb(53, 162, 235)",
          pointBorderColor: "rgb(53, 162, 235)",
          pointBackgroundColor: "rgb(53, 162, 235)",
          tension: 0.4,
          data: [],
          // yAxisID: 'y1',
        },
      ],
    };
  },
  options: gradientChartOptionsConfiguration,
};

// ##############################
// Dashboard view - Bar Chart - Card
// #############################

export const graficaPuertas = {
  data: (canvas) => {
    var ctx = canvas.getContext("2d");
    return {
      labels: [],
      datasets: [
        {
          backgroundColor: "rgb(247, 169, 79)",
          barThickness: 10,
          borderRadius: 5,
          borderColor: 'rgb(247, 169, 79)',
          pointBorderColor: "#FFF",
          pointBackgroundColor: 'rgb(247, 169, 79)',
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          fill: true,
          borderWidth: 1,
          data: [],
        },
      ],
    };
  },
  options: graficaPuertasOptions,
};


