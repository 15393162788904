/*!
=========================================================
* Argon Dashboard React - v1.2.1
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

// core components
import AuthFooter from "components/Footer/AuthFooter.js";
import HeaderLogin from "components/PanelHeader/HeaderLogin.js";
import routes from "routes.js";

const Auth = (props) => {
  if(!localStorage.getItem('credentials')){
    const mainContent = React.useRef(null);
    const location = useLocation();

    React.useEffect(() => {
      document.body.classList.add("#ffffff");
      return () => {
        document.body.classList.remove("#ffffff");
      };
    }, []);
    React.useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
      return routes.map((prop, key) => {
        if (prop.layout === "/auth") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
    };

    return (
      <>
        <div  ref={mainContent} style={{ position: "absolute", height: "100%", width: "100%"}}>
          <HeaderLogin/>
          <Container>
            <Row style={{height:'80vh'}}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
            <AuthFooter fluid/>
          </Container>
        </div>
      </>
    );
  }
  else{
    var credential = JSON.parse(localStorage.getItem('credentials'))
    if(credential.rol && credential.rol == 1){
      return(
        <Redirect to="/admin/dashboard" />
      );
    }
    else{
      return(
        <Redirect to="/client/dashboard" />
      );
    }
  }

}

export default Auth;