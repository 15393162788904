import "./style.css";

export const ResumeInfoCard = ({ title, value, color }) => {
  return (
    <div className="col-xl-4 col-lg-4 rounded-2">
      <div className="card card-stats py-1 shadow-sm border border-2">
        <div className="card-body">
          <div className="row align-items-center">
            <div className={`col color-tag ${color}`}>
              <h6 className="text-muted">{title}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <span className="h3 mb-0 font-weight-bold">{value ?? 'Sin datos'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeInfoCard;
