import {React, useState, useEffect} from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  FormFeedback,
  InputGroupText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import {
  API_URL,
  MYPROFILE,
  EDIT_USERS_HEADERS
} from "variables/connection.js";
import { Link } from "react-router-dom";

const Perfil = () => {
	const credentials = JSON.parse(localStorage.getItem('credentials'));
	const [user, setUser] = useState('');
	const [customerID, setClient] = useState(credentials.cliente);
	const [rolID, setRol] = useState(credentials.rol);
	const [invalidUser, setInvalidU] = useState(false);
  
	const enviarDatos = (event) => {
		event.preventDefault();
		setInvalidU(false);
		if( user == '') setInvalidU(true);
		const body = {
			user: user,
			customerID: customerID,
			rolID: rolID,
		};
		fetch( API_URL + '/user/' + credentials.id, EDIT_USERS_HEADERS(credentials, body))
			.then(response => {
				if(response.status === 200) {
					window.location.href = (credentials.rol == 1 ? '/admin' : '/client') + '/myprofile';
				}
			})
			.catch(error => console.log("Error"));
			
	}
	
	useEffect (() => {
		fetch(API_URL + '/user/' + credentials.id, MYPROFILE(credentials.token))
			.then((res) => res.json())
			.then (res => {
				setUser(res[0][0].usr_logon);
			});

	}, []);
	
  return (
    <>
      <PanelHeader size="sm" />
      {/* Page content */}
      <div className="content">
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
				<CardHeader> 
				<Row>
					<div className="col-10">
						<CardTitle className="heading-small text-muted ml-3" tag="h3">
							Mi Perfil
						</CardTitle>
					</div>
				</Row>
				</CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label className="form-control-label" htmlFor="input-username">
                            Nombre del usuario
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons users_single-02" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input 
							invalid = {invalidUser}
							className="form-control-alternative" 
							value={user} 
							onChange = {event => setUser (event.target.value)}>
						  </Input>
						  <FormFeedback invalid={invalidUser.toString()}>
							  El nombre de usuario es obligatorio.
						  </FormFeedback>
						  </InputGroup>
                        </FormGroup>
                      </Col>
					  <Col lg="6">
                        <FormGroup className={"no-border"}>
						<label className="form-control-label" htmlFor="input-username">
                            Cliente
                        </label>
						<InputGroup className="input-group-alternative">
						  <InputGroupAddon addonType="prepend">
							<InputGroupText>
							  <i className="now-ui-icons business_briefcase-24" />
							</InputGroupText>
						  </InputGroupAddon>
                          <Input className="form-control-alternative" value={credentials.cli_nombre} readOnly></Input>
						  </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
					<div className="text-center">
						<Link to={(credentials.rol == 1 ? '/admin' : '/client') + '/dashboard'}>
							<Button color="dark">
								Salir
							</Button>
						</Link>
						<Button color="primary" type="button" onClick={enviarDatos}>
						  Actualizar
						</Button>
					</div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Perfil;