import { React, useState, useEffect } from "react";
import { Button, ButtonGroup, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { ResponsiveHeatMap } from "@nivo/heatmap";

function TermometroSemanal(props) {
  const [formatoTermometro, setFormatoTermometro] = useState(">-,.2d")
  const [leyendaTermometro, setLeyendaTermometro] = useState("Conteo de personas →")
  const [datosHoraSemanal, setDatosHoraSemanal] = useState(props.conteoHoraSemanal);
  // 0 -> Conteo, 1 -> Porcentaje
  const [tipoTermometro, setTipoTermometro] = useState(0);
 
  useEffect(() => {
    setFormatoTermometro(tipoTermometro == 0 ? ">-,.2d" : ">-.2%");
    setLeyendaTermometro(tipoTermometro == 0 ? "Conteo de personas →" : "Porcentaje de personas →");
    setDatosHoraSemanal(tipoTermometro == 0 ? props.conteoHoraSemanal : props.porcentajeHoraSemanal);
  }, [tipoTermometro, props.conteoHoraSemanal, props.porcentajeHoraSemanal]);

  return (
    <Card className="card-chart" >
      <CardHeader>
        <div className="d-flex justify-content-between">
          <CardTitle tag="h4">Termómetro Semanal</CardTitle>
          <ButtonGroup>
            <Button
              color="primary"
              outline={tipoTermometro != 0}
              onClick={() => setTipoTermometro(0)}
              active={tipoTermometro == 0}
            >
              + Suma
            </Button>
            <Button
              color="primary"
              outline={tipoTermometro != 1}
              onClick={() => setTipoTermometro(1)}
              active={tipoTermometro == 1}
            >
              % Porcentajes
            </Button>
          </ButtonGroup>
        </div>
        <hr />
      </CardHeader>
      <CardBody>
        <div className="chart-area">
          <ResponsiveHeatMap
            data={datosHoraSemanal}
            margin={{ top: 57, right: 30, bottom: 60, left: 50 }}
            valueFormat={formatoTermometro}
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -90,
              legendPosition: "middle",
              legendOffset: -35,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Hora",
              legendPosition: "middle",
              legendOffset: -60,
            }}
            colors={{
              type: 'diverging',
              scheme: 'reds',
              /*maxValue:7000,*/
              divergeAt: 0.9,
            }}

            labelTextColor="#000"
            
            theme={{
              labels: {
                text: {
                  textShadow: '0 0 5px #fff, 0 0 6px #fff, 0 0 7px #fff',
                  fontWeight : 'bold',
                },
              },
              
            }}
            
            legends={[
              {
                anchor: "bottom",
                translateX: 0,
                translateY: 30,
                length: 300,
                thickness: 8,
                direction: "row",
                tickPosition: "after",
                tickSize: 3,
                tickSpacing: 4,
                tickOverlap: false,
                tickFormat: formatoTermometro,
                title: leyendaTermometro,
                titleAlign: "start",
                titleOffset: 4,
              },
            ]}
          />
        </div>
      </CardBody>
    </Card>
  );
}

export default TermometroSemanal;
