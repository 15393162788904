/*!

=========================================================
* Now UI Dashboard React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v1.5.0";
import "assets/css/demo.css";

import AdminLayout from "layouts/Admin.js";
import ClienteLayout from "layouts/Cliente.js";
import Auth from "layouts/Auth.js";
import {
  API_URL,
  TOKEN_VALIDATOR,
} from "variables/connection.js";


const credentials = JSON.parse(localStorage.getItem('credentials'));
if(credentials){
  fetch( API_URL + '/validateToken', TOKEN_VALIDATOR(credentials))
  .then(response => {
    if(response.status !== 200) throw new Error(response.status);
  })
  .catch(error => {
    localStorage.removeItem('credentials');
    var baseURL = location.origin
    location.href = baseURL + "/auth/login?isOldToken"
  });
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/client" render={(props) => <ClienteLayout {...props} />} />
	    <Route path="/auth"  render={(props) => <Auth {...props} />} />
      <Redirect to="/auth/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
