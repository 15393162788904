import {
  //UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  //NavItem,
  //NavLink,
  //Nav,
  Container,
  //Row,
  //Col,
} from "reactstrap";

import logo from "assets/img/logo-bk.svg";


const HeaderLogin = () => {
return (
    <>
      <Navbar className="navbar-horizontal navbar-dark navbar-transparent" expand="md">
        <Container className="px-0">
          <NavbarBrand
            href="https://smartmetrics.mx/" 
            className="simple-text logo-normal" 
            target="_blank"
            
            >
              <img className="img-fluid" width="120"  alt="SmartMetrics" src={logo}/>
          </NavbarBrand>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderLogin;